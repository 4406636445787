import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/tools/Navbar/Navbar";
import PalletListPage from "./components/PalletList/PalletListPage/PalletListPage";
import PalletDetailPage from "./components/PalletDetail/PalletDetailPage/PalletDetailPage";
import { Auth, Hub } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Typography, Button } from "@mui/material";
import '@aws-amplify/ui-react/styles.css';
import "./styles.scss";
import "./styles.css";
import Logo from "./assets/logo.png";




const components = {
  Header() {
    return (
      <div>
        <img src={Logo} alt="QCBot Logo" className="logo" style={{ width: "280px", height: "90px" }} />
        <Typography variant="h5" component="h2" className="app-name-font">
          QC BOT
        </Typography>
      </div>
    );
  },
};

const App = () => {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => setUser(currentUser))
      .catch((response) => console.log('response.data.Username'));

    return unsubscribe;
  }, []);

  return (
    <div className={!user ? 'login-panel' : ""}>
      <Authenticator slot="sign-in" hideSignUp components={components}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route
              path="/pallet/:inference_id/:dc_id"
              element={<PalletDetailPage />}
            />
            <Route exact path="/" element={<PalletListPage />} />
          </Routes>
        </BrowserRouter>
      </Authenticator>

      {!user ?
        <div className='sso-cont'>
          <Button className='SSO-button' onClick={() => Auth.federatedSignIn({ provider: "rpcidentityprovider" })}>Rehrig User? Click Here</Button>
        </div>
        : ""
      }
    </div>
  );
};

export default App;
