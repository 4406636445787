import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, Widgets } from "@material-ui/icons";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import * as QCBotActions from "../../../store/qcbot/qcbot.actions";
import * as QCBotService from "../../../services/qcbot.service";
import ImageViewer from "../ImageViewer/ImageVIewer";
import InferenceListItem from "../InferenceListItem/InferenceListItem";
import palletIcon from "../../../assets/pallet.svg";
import { getInferenceItemStatus } from "../../../helpers";
import AsyncSelect from "react-select/async";
import placeHolder from '../../../assets/image-placeholder-2.png';

const sides = ["front", "back", "left", "right"];

const PalletDetailPage = (props) => {
  const [currentSide, setCurrentSide] = useState("front");
  const {
    fetchPalletDetail,
    palletDetail,
    confirmActualLoading,
    customerSkus,
    fetchCustomerSkus,
    addExtraSku,
    newSkuLoading,
  } = props;
  const { inference_id, dc_id } = useParams();
  const [boundingData, setBoundingData] = useState(null);
  const [activeItem, setActiveItem] = useState(-1);
  const [boundingColor, setBoundingColor] = useState("#fbc813");
  const [showNewForm, setShowNewForm] = useState(false);
  const [extraSkuImages, setExtraSkuImages] = useState({});
  const [extraSkuType, setExtraSkuType] = useState(null);
  const [auditValue, setAuditValue] = useState("");
  const [enableAddSkuButton, setEnableAddSkuButton] = useState(true);
  const [isPickErr, setIsPickErr] = useState('');
  const [selectedIsPickErr, setSelectedIsPickErr] = useState(0);

  useEffect(() => {
    if (!confirmActualLoading) fetchPalletDetail(inference_id, dc_id);
  }, [fetchPalletDetail, inference_id, dc_id, confirmActualLoading]);

  useEffect(() => {
    if (newSkuLoading === false) {
      setShowNewForm(false);
      setExtraSkuImages({});
      setExtraSkuType(null);
      setAuditValue("");

      fetchPalletDetail(inference_id, dc_id);
    }
  }, [newSkuLoading]);

  useEffect(() => {
    if (palletDetail?.bounding_boxes_url) {
      fetch(palletDetail?.bounding_boxes_url)
        .then((raw) => raw.json())
        .then((data) => setBoundingData(data))
        .catch((err) => console.log(err));
    }
  }, [palletDetail?.bounding_boxes_url]);

  useEffect(() => {
    if (palletDetail?.customer_id) fetchCustomerSkus(palletDetail?.customer_id);
  }, [palletDetail?.customer_id]);


  useEffect(() => {
    if (extraSkuType) {
      const currentSKU = customerSkus?.find(
        (item) => item.customer_sku_id === extraSkuType.value
      );

      if (currentSKU)
        QCBotService.getSKUPhotos(
          currentSKU.upc,
          currentSKU.reference_number
        ).then(({ data }) => {
          setExtraSkuImages(data);
        });
    }
  }, [extraSkuType, customerSkus]);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#fff"
  });

  useEffect(() => {
    if (props.palletDetail) {
      if (props.palletDetail.is_picker_err === false) {
        setIsPickErr('No')
      } else if (props.palletDetail.is_picker_err === true) {
        setIsPickErr('Yes')
      } else {
        setIsPickErr('')
      }
    } else {
      setIsPickErr('')
    }
  }, [props.palletDetail])


  const sortedInferences = useMemo(() => {
    return (
      props?.palletDetail?.inference_result?.sort((a, b) => {
        const aStatus = getInferenceItemStatus(a),
          bStatus = getInferenceItemStatus(b);
        if (aStatus === bStatus) return 0;

        const statusOrder = [
          "AUDITED",
          "EXTRA",
          "MISSING",
          "UNTRAINED",
          "CAN'T CONFIRM",
          "NO ERROR",
        ];

        const aOrder = statusOrder.indexOf(aStatus),
          bOrder = statusOrder.indexOf(bStatus);

        if (aOrder === -1 || bOrder === -1) return 0;

        return aOrder > bOrder ? 1 : -1;

      }) || []
    );
  }, [props?.palletDetail?.inference_result]);

  const customerSkuOptions = useMemo(() => {
    const idsToFilterOut =
      props?.palletDetail?.inference_result?.map(
        (item) => item.vor_reference_number
      ) || [];

    let filteredSkus = customerSkus;

    if (idsToFilterOut.length > 0) {
      filteredSkus = customerSkus?.filter(
        (item) => !idsToFilterOut.includes(item.reference_number)
      );
    }

    return (
      filteredSkus?.map((item) => ({
        value: item.customer_sku_id,
        label: item.long_description,
      })) || []
    );
  }, [customerSkus, props?.palletDetail?.inference_result]);

  const allIssuesFixed = useMemo(() => {
    if (!props?.palletDetail?.inference_result) return false;
    for (const inferenceItem of props?.palletDetail?.inference_result) {
      const status = getInferenceItemStatus(inferenceItem);
      const notAudited = ["EXTRA", "MISSING", "UNTRAINED"];

      if (notAudited.indexOf(status) > -1) return false;
    }
    return true;
  }, [props?.palletDetail?.inference_result]);

  const errorCount = useMemo(
    () =>
      props?.palletDetail?.inference_result?.filter(
        (item) => item.status !== "MATCHED"
      )?.length,
    [props?.palletDetail?.inference_result]
  );

  const onConfirmAudit = () => {
    props.confirmAudit(inference_id, dc_id, selectedIsPickErr);
  };

  const onNewSku = () => {
    setShowNewForm(!showNewForm);
  };

  const manageEnableAddSkuChange = (value) => {
    setExtraSkuType(value)
    if (auditValue !== "") {
      setEnableAddSkuButton(false)
    }
  }

  const manageQuantityChange = (e) => {
    setAuditValue(e.target.value);
    if (extraSkuType !== null) {
      setEnableAddSkuButton(false)
    }
  }

  useEffect(() => {
    if (isPickErr !== undefined) {
      if (isPickErr === 'Yes') {
        setSelectedIsPickErr(1);
      } else {
        setSelectedIsPickErr(0)
      }
    }
  }, [isPickErr])

  const handleIsPickErrChange = (event) => {
    setIsPickErr(event.target.value)
  }

  const onAddExtraSku = () => {
    const currentSKU = customerSkus?.find(
      (item) => item.customer_sku_id === extraSkuType.value
    );

    const actualCount = parseInt(auditValue);

    addExtraSku({
      inference_id,
      customer_id: palletDetail?.customer_id,
      customer_sku_id: currentSKU.customer_sku_id,
      dc_id,
      qc_bot_audited_quantity: isNaN(actualCount) ? 0 : actualCount,
      upc: currentSKU.upc,
    });
  };

  return (
    <>
      {props.loading && (
        <div className="loader-overlay">
          <CircularProgress size={80} />
        </div>
      )}
      <div className="container">
        <Grid container justify="space-between">
          <a className="back-container" href="/">
            <ChevronLeft />
            <Typography>BACK TO PALLET LIST</Typography>
          </a>

          <Typography>
            {palletDetail?.customer_picklist_id} - {palletDetail?.operator_name}
          </Typography>
        </Grid>

        <Grid container justify="space-between">
          <div className="pallet-detail-title-container">
            <img src={palletIcon} className="pallet-icon" alt="Pallet Icon" />
            <Typography component="span" className="pallet-detail-title">
              {palletDetail?.store_name}
            </Typography>
          </div>

          <div>
            {palletDetail?.contains_un_trained_sku && (
              <div className="chip weight-heuristic-chip">
                Contains Untrained SKU
              </div>
            )}
            {palletDetail?.is_irregular_weight && (
              <div className="chip weight-heuristic-chip" style={{ marginBottom: '2px' }}>
                Irregular weight, please review
              </div>
            )}
            {!palletDetail?.contains_un_trained_sku &&
              palletDetail?.is_weight_heuristic_applied && (
                <div className="chip weight-heuristic-chip">
                  Please confirm verified pallet
                </div>
              )}
            {palletDetail?.is_system_error ? (
              <div className="chip weight-heuristic-chip">
                Audit needed due to System Error
              </div>
            ) : ''}
          </div>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={8}>
            {(palletDetail?.camera_images_urls?.front !== null ||
              palletDetail?.camera_images_urls?.back !== null ||
              palletDetail?.camera_images_urls?.left !== null ||
              palletDetail?.camera_images_urls?.right !== null
            ) ?
              <Grid container justify="center">
                <div className="image-viewer-container">
                  <ImageViewer
                    src={
                      palletDetail?.camera_images_urls &&
                      palletDetail?.camera_images_urls[currentSide]
                    }
                    boundaries={
                      boundingData && activeItem >= 0
                        ? boundingData[currentSide][
                        sortedInferences[activeItem]?.vor_reference_number
                        ]
                        : null
                    }
                    color={boundingColor}
                  />
                </div>
              </Grid> :
              <Grid container justify="center">
                <div className="image-viewer-container"
                  style={{ textAlign: 'center' }}
                >
                  <img style={{ marginTop: '-10px' }} src={placeHolder} title="No Image Found" />
                  <p style={{ textAlign: 'center', paddingTop: '10px', fontWeight: 'bold', }}
                  >No image found</p>
                </div>
              </Grid>
            }
            <Grid container justify="center">
              {sides.map((side) => (
                <img
                  key={`thumbnail${side}`}
                  alt="Thumbnail"
                  src={
                    (palletDetail?.thumbnail_urls &&
                      palletDetail?.thumbnail_urls[side]) ||
                      (palletDetail?.camera_images_urls &&
                        palletDetail?.camera_images_urls[side]) ? palletDetail?.camera_images_urls[side] : placeHolder
                  }
                  className={`pallet-detail-thumbnail ${side === currentSide ? "active" : ""
                    }`}
                  onClick={() => setCurrentSide(side)}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            {errorCount !== null && (
              <div className="error-count">
                VISION FOUND {errorCount} ERRORS
              </div>
            )}
            {sortedInferences &&
              sortedInferences.map((item, idx) => (
                <InferenceListItem
                  data={item}
                  inferenceId={inference_id}
                  dcId={dc_id}
                  key={`inference_list_item${idx}`}
                  expanded={idx === activeItem}
                  onExpand={(color) => {
                    if (color !== undefined) {
                      setBoundingColor(color);
                      setActiveItem(idx);
                    } else {
                      setBoundingColor("#db2d2d");
                      setActiveItem(idx);
                    }
                  }}
                />
              ))}

            {showNewForm && (
              <div className="new-sku-form">
                <Card className="new-sku-card">
                  <AsyncSelect
                    defaultOptions={customerSkuOptions}
                    value={extraSkuType}
                    onChange={(value) => manageEnableAddSkuChange(value)}
                    loadOptions={(inputValue, callback) => {
                      return callback(
                        customerSkuOptions.filter(
                          (item) =>
                            item.label
                              .toLowerCase()
                              .indexOf(inputValue.toLocaleLowerCase()) > -1
                        )
                      );
                    }}
                  />

                  <Grid
                    container
                    justify="center"
                    className="sku-image-container"
                  >
                    <Grid item className="sku-image-item">
                      {extraSkuImages?.side_url && (
                        <img
                          src={extraSkuImages?.side_url}
                          className="sku-image"
                        />
                      )}
                      <Typography component="p">SIDE OF SKU</Typography>
                    </Grid>

                    <Grid item className="sku-image-item">
                      {extraSkuImages?.end_url && (
                        <img
                          src={extraSkuImages?.end_url}
                          className="sku-image"
                        />
                      )}
                      <Typography component="p">END OF SKU</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justify="center"
                    className="inference-detail-content"
                  >
                    <div>
                      <Typography className="inference-detail-label">
                        ACTUAL
                      </Typography>
                      <input
                        type="text"
                        className="inference-detail-actual-quantity-input"
                        value={auditValue}
                        onChange={(e) =>
                          manageQuantityChange(e)
                        }
                      />
                    </div>
                  </Grid>

                  <Grid
                    container
                    justify="center"
                    className="inference-detail-content"
                  >
                    <Button
                      className="primary"
                      size="large"
                      onClick={onAddExtraSku}
                      disabled={enableAddSkuButton}
                    >
                      {newSkuLoading ? (
                        <CircularProgress color="white" size={30} />
                      ) : (
                        "Add Extra SKU"
                      )}
                    </Button>
                  </Grid>
                </Card>
              </div>
            )}

            <div className="new-sku-container">
              <Button className="primary" size="medium" onClick={onNewSku}>
                Add New SKU
              </Button>
            </div>
            <div className="ispickerr-container">
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"
                  sx={{ color: '#000' }}
                >Was a picker error found during this review?</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  sx={{ margin: '10px 15px' }}
                  value={isPickErr}
                  onChange={handleIsPickErrChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes"
                    sx={{ marginRight: '30px' }}
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="complete-audit-container">
              <Button
                className="primary"
                size="large"
                onClick={onConfirmAudit}
                disabled={!allIssuesFixed ? true : isPickErr === '' ? true : false}
              >
                COMPLETE AUDIT
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    palletDetail: state.palletDetail,
    loading: state.loading,
    confirmActualLoading: state.confirmActualLoading,
    newSkuLoading: state.newSkuLoading,
    customerSkus: state.customerSkus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPalletDetail: (inferenceId, dcId) =>
      dispatch(QCBotActions.QCBOT_LOAD_PALLET_DETAIL(inferenceId, dcId)),
    confirmAudit: (inferenceId, dcId, selectedIsPickErr) =>
      dispatch(QCBotActions.QCBOT_CONFIRM_AUDIT(inferenceId, dcId, selectedIsPickErr)),
    fetchCustomerSkus: (customerId) =>
      dispatch(QCBotActions.QCBOT_LIST_CUSTOMER_SKUS(customerId)),
    addExtraSku: (data) => dispatch(QCBotActions.QCBOT_ADD_EXTRA_SKU(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PalletDetailPage);
