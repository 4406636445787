import * as ActionTypes from "./qcbot.actiontypes";

export const initialState = {
  pallets: [],
  palletDetail: {},
  customers: [],
  loading: false,
  confirmActualLoading: false,
  customerSkus: [],
  newSkuLoading: null,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case ActionTypes.QCBOT_LOAD_PALLETS:
      newState.loading = true;
      break;
    case ActionTypes.QCBOT_LOAD_PALLETS_SUCCESS:
      newState.pallets = action.payload;
      newState.loading = false;
      break;
    case ActionTypes.QCBOT_LOAD_PALLETS_FAILURE:
      newState.loading = false;
      break;
    case ActionTypes.QCBOT_LOAD_PALLET_DETAIL:
      newState.loading = true;
      break;
    case ActionTypes.QCBOT_LOAD_PALLET_DETAIL_FAILURE:
      newState.loading = false;
      break;
    case ActionTypes.QCBOT_LOAD_PALLET_DETAIL_SUCCESS:
      newState.loading = false;
      newState.palletDetail = action.payload;
      break;
    case ActionTypes.QCBOT_LOAD_CUSTOMERS_SUCCESS:
      newState.customers = action.payload.customers;
      break;
    case ActionTypes.QCBOT_AUDIT_PALLET:
      newState.confirmActualLoading = true;
      break;
    case ActionTypes.QCBOT_AUDIT_PALLET_SUCCESS:
      newState.confirmActualLoading = false;
      break;
    case ActionTypes.QCBOT_CONFIRM_AUDIT_SUCCESS:
      window.location.href = "/";
      newState.loading = false;
      break;
    case ActionTypes.QCBOT_CONFIRM_AUDIT_FAILURE:
      newState.loading = false;
      break;
    case ActionTypes.QCBOT_SET_LOADING:
      newState.loading = action.payload;
      break;
    case ActionTypes.QCBOT_LIST_CUSTOMER_SKUS_SUCCESS:
      newState.customerSkus = (action.payload && action.payload.skus) || [];
      break;
    case ActionTypes.QCBOT_ADD_EXTRA_SKU:
      newState.newSkuLoading = true;
      break;
    case ActionTypes.QCBOT_ADD_EXTRA_SKU_SUCCESS:
      newState.newSkuLoading = false;
      break;
    case ActionTypes.QCBOT_CONFIRM_AUDIT_FAILURE:
      newState.newSkuLoading = false;
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
