import * as QCBotActions from "./qcbot.actions";
import * as ActionTypes from "./qcbot.actiontypes";
import * as QCBotService from "../../services/qcbot.service";
import { takeEvery, call, put } from "redux-saga/effects";

export function* fetchPallets(action) {
  try {
    const { data } = yield call(
      QCBotService.getPallets,
      action.payload.startDate,
      action.payload.endDate,
      action.payload.dcId,
      action.payload.halfPallets,
      action.payload.fullPallets,
      action.payload.selectAll,
      action.payload.authToken
    );

    if (data && data.pallets)
      yield put(QCBotActions.QCBOT_LOAD_PALLETS_SUCCESS(data.pallets));
  } catch (error) {
    console.log(error);
    yield put(QCBotActions.QCBOT_LOAD_PALLETS_FAILURE(error));
  }
}

export function* fetchPalletDetail(action) {
  try {
    const { data } = yield call(
      QCBotService.getPalletDetail,
      action.payload.inferenceId,
      action.payload.dcId
    );

    if (data) yield put(QCBotActions.QCBOT_LOAD_PALLET_DETAIL_SUCCESS(data));
  } catch (error) {
    console.log(error);
    yield put(QCBotActions.QCBOT_LOAD_PALLET_DETAIL_FAILURE(error));
  }
}

export function* fetchCustomers(action) {
  try {
    const { data } = yield call(QCBotService.getCustomers);

    if (data) yield put(QCBotActions.QCBOT_LOAD_CUSTOMERS_SUCCESS(data));
  } catch (error) {
    console.log(error);
    yield put(QCBotActions.QCBOT_LOAD_CUSTOMERS_FAILURE(error));
  }
}

export function* auditPallet(action) {
  try {
    const { inferenceId, dcId, data: requestData } = action.payload;
    const { data } = yield call(
      QCBotService.auditPallet,
      inferenceId,
      dcId,
      requestData
    );

    if (data === "Updated successfully")
      yield put(QCBotActions.QCBOT_AUDIT_PALLET_SUCCESS());
  } catch (error) {
    console.log(error);
    yield put(QCBotActions.QCBOT_AUDIT_PALLET_FAILURE(error));
  }
}

export function* confirmAuditPallet(action) {
  try {
    yield put(QCBotActions.QCBOT_SET_LOADING(true));
    const { inferenceId, dcId, selectedIsPickErr } = action.payload;
    const { data } = yield call(QCBotService.confirmAudit, inferenceId, dcId, selectedIsPickErr);

    if (data === "Updated successfully")
      yield put(QCBotActions.QCBOT_CONFIRM_AUDIT_SUCCESS());
  } catch (error) {
    console.log(error);
    yield put(QCBotActions.QCBOT_CONFIRM_AUDIT_FAILURE(error));
  }
}

export function* listCustomerSKUs(action) {
  try {
    const { data } = yield call(
      QCBotService.getCustomersSKUs,
      action.payload.customerId
    );

    if (data) yield put(QCBotActions.QCBOT_LIST_CUSTOMER_SKUS_SUCCESS(data));
  } catch (error) {
    console.log(error);
    yield put(QCBotActions.QCBOT_LIST_CUSTOMER_SKUS_FAILURE(error));
  }
}

export function* addExtraSku(action) {
  try {
    const { data } = yield call(QCBotService.addExtraSku, action.payload);

    if (data === "Updated successfully")
      yield put(QCBotActions.QCBOT_ADD_EXTRA_SKU_SUCCESS());
  } catch (error) {
    console.log(error);
    yield put(QCBotActions.QCBOT_ADD_EXTRA_SKU_FAILURE(error));
  }
}

export function* EffectListener() {
  yield takeEvery(ActionTypes.QCBOT_LOAD_PALLETS, fetchPallets);
  yield takeEvery(ActionTypes.QCBOT_LOAD_PALLET_DETAIL, fetchPalletDetail);
  yield takeEvery(ActionTypes.QCBOT_LOAD_CUSTOMERS, fetchCustomers);
  yield takeEvery(ActionTypes.QCBOT_AUDIT_PALLET, auditPallet);
  yield takeEvery(ActionTypes.QCBOT_CONFIRM_AUDIT, confirmAuditPallet);
  yield takeEvery(ActionTypes.QCBOT_LIST_CUSTOMER_SKUS, listCustomerSKUs);
  yield takeEvery(ActionTypes.QCBOT_ADD_EXTRA_SKU, addExtraSku);
}